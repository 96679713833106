// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Dashboard = ({ token }) => {
  const [forms, setForms] = useState([]);
  const [format, setFormat] = useState('xml');

  useEffect(() => {
    const fetchForms = async () => {
      const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}.${format}?access_token=${token}`;

      try {
        const response = await axios.get(apiEndpoint);
        setForms(response.data.forms);
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    };

    fetchForms();
  }, [token, format]);

  return (
    <div>
      <h2>Forms List</h2>
      <label>
        Select format:
        <select value={format} onChange={(e) => setFormat(e.target.value)}>
          <option value="json">JSON</option>
          <option value="xml">XML</option>
          <option value="plist">PLIST</option>
        </select>
      </label>
      <ul>
        {forms.map((form, index) => (
          <li key={index}>{form.name}</li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;
