// src/components/Login.js
import React from 'react';

const Login = () => {
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const authEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
  const redirectUri = `${window.location.origin}/callback`;

  const handleLogin = () => {
    const authUrl = `${authEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
    window.location.href = authUrl;
  };

  return (
    <div>
      <h2>FormAssembly Backup</h2>
      <button onClick={handleLogin}>Authorize with FormAssembly</button>
    </div>
  );
};

export default Login;
