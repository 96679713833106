// src/components/Callback.js
import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Callback = ({ setToken }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      getAccessToken(code);  // Send the code to your Node.js server
    } else {
      console.error('Authorization code not found in URL.');
    }
  });

  const getAccessToken = async (authorizationCode) => {
    try {
      const response = await axios.post('http://localhost:3001/get-token', {
        code: authorizationCode,  // The code received in the callback
      });

      const { access_token } = response.data;
      console.log('Token response:', response.data);

      if (access_token) {
        setToken(access_token);
        navigate('/dashboard');  // Redirect to the dashboard or any other page
      } else {
        console.error('Access token not received.');
      }
    } catch (error) {
      console.error('Error fetching access token:', error.response || error.message || error);
      if (error.response) {
        console.error('Response status:', error.response.status);
        console.error('Response data:', error.response.data);
      }
    }
  };

  return <div>Loading...</div>;
};

export default Callback;
